
import { Form, Field, ErrorMessage } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { defineComponent, ref } from "vue";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2";

enum RemoveOTPError {
  INVALID_PASSWORD = "invalid_password"
}

export default defineComponent({
  name: "disable-otp",
  components: { Form, Field, ErrorMessage },
  setup: function() {
    const store = useStore();
    const router = useRouter();
    const loading = ref<boolean>(false);
    const validationSchema = Yup.object().shape({
      userPassword: Yup.string().required("Please enter your password")
    });

    function setLoading(value: boolean): void {
      loading.value = value;
    }

    function handleCancelButton(): void {
      router.push("/users/me/user-security");
    }

    function handleFormSubmit(formData: { userPassword: string }): void {
      setLoading(true);
      store
        .dispatch("OtpModule/removeTwoFactorAuth", formData.userPassword)
        .then(() => {
          setLoading(false);
          store.dispatch(Actions.SET_FETCH_USER, true);
          router.push("/users/me/user-security").then(() => {
            Swal.fire({
              icon: "success",
              timer: 1400,
              title: "Your two-factor authentication was removed successfully"
            });
          });
        })
        .catch(err => {
          setLoading(false);
          const response = err.response;
          if (response.data) {
            let message = err;
            if (response.data.type === RemoveOTPError.INVALID_PASSWORD) {
              message = "Invalid password";
            }
            Swal.fire({
              icon: "error",
              timer: 1400,
              title: message
            });
          }
        });
    }

    return {
      handleCancelButton,
      validationSchema,
      handleFormSubmit,
      loading
    };
  }
});
